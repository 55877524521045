import React from 'react'
import Calculator from './Calculator'

import style from './style.css'


let App = () => {
    return (
        <div>
            <Calculator />
        </div>
    )
}

export default App